import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter, Role } from "@impacgroup/angular-oauth-base";
import { map } from "rxjs/operators";
import { classToPlain, plainToClass } from "class-transformer";
import { LinkedInConfig } from "../../models/LinkedInConfig";

export interface ILinkedInConfigConfigService {
    linkedInConfigAPI: string;
}

export const LinkedInConfigConfigService = new InjectionToken<ILinkedInConfigConfigService>("LinkedInConfigConfig");

@Injectable()
export class LinkedInConfigService {
    constructor(@Inject(LinkedInConfigConfigService) private linkedInConfigConfig: ILinkedInConfigConfigService, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {}

    public linkedInConfigDetail(): Observable<LinkedInConfig> {
        return this.http
            .get<Object>(this.getLinkedInConfigServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(LinkedInConfig, result)));
    }

    public updateLinkedInConfig(linkedInConfig: LinkedInConfig): Observable<LinkedInConfig> {
        return this.http
            .patch<Object>(this.getLinkedInConfigServiceUrl(""), classToPlain(linkedInConfig), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToClass(LinkedInConfig, result)));
    }

    private getLinkedInConfigServiceUrl(postfix: string): string {
        return this.linkedInConfigConfig.linkedInConfigAPI + postfix;
    }
}
