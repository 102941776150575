import { ModalModule } from "ngx-bootstrap/modal";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

import { LinkedInConfigService, ILinkedInConfigConfigService, LinkedInConfigConfigService } from "./linkedinconfig.service";
import { LinkedInConfigComponent } from "./linkedinconfig.component";
import { TranslateModule } from "@ngx-translate/core";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { Route } from "@angular/router";

@NgModule({
    declarations: [LinkedInConfigComponent],
    imports: [CommonModule, FormsModule, BsDropdownModule, ModalModule, NgSelectModule, TranslateModule, OAuthModule],
    exports: [],
    providers: []
})
export class LinkedInConfigModule {
    static forRoot(linkedInConfigConfig: ILinkedInConfigConfigService): ModuleWithProviders<LinkedInConfigModule> {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: LinkedInConfigModule,
            providers: [
                LinkedInConfigService,
                {
                    provide: LinkedInConfigConfigService,
                    useValue: linkedInConfigConfig
                }
            ]
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                component: LinkedInConfigComponent
            }
        ];
    }
}
