import { Component, OnInit } from "@angular/core";
import { AlertService, BaseComponent } from "@impacgroup/angular-baselib";
import { LinkedInConfigService } from "./linkedinconfig.service";
import { TranslateService } from "@ngx-translate/core";
import { LinkedInConfig } from "../../models/LinkedInConfig";

@Component({
    selector: "lib-smtp-linkedinconfig",
    templateUrl: "./linkedinconfig.component.html",
    styleUrls: ["linkedinconfig.component.scss"]
})
export class LinkedInConfigComponent extends BaseComponent implements OnInit {
    public linkedInConfig: LinkedInConfig;

    constructor(private linkedInConfigService: LinkedInConfigService, private alertService: AlertService, private translateService: TranslateService) {
        super();

        this.subscriptions.push(
            this.linkedInConfigService.linkedInConfigDetail().subscribe(
                (result) => {
                    this.linkedInConfig = result;
                },
                (err) => {
                    console.log(err);
                }
            )
        );
    }

    ngOnInit(): void {}

    save() {
        this.subscriptions.push(
            this.linkedInConfigService.updateLinkedInConfig(this.linkedInConfig).subscribe((result) => {
                this.alertService.addSuccess(this.translateService.instant("linkedinconfig.success"));
                this.linkedInConfig = result;
            })
        );
    }
}
