<div class="content__inner">
    <header class="content__title">
        <h1>{{ 'linkedinconfig.title' | translate }}</h1>
    </header>

    <div class="card">
        <div class="card-body">
            <form name="form" class="row" *ngIf="linkedInConfig" (ngSubmit)="f.form.valid && save()" #f="ngForm" novalidate>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ 'linkedinconfig.props.csrfToken' | translate }}</label>
                        <input name="csrfToken" type="text" class="form-control" [(ngModel)]="linkedInConfig.csrfToken" #csrfToken="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && csrfToken.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ 'linkedinconfig.validation.csrfToken' | translate }}
                        </div>
                    </div>

                    <div class="form-group">
                        <label>{{ 'linkedinconfig.props.cookie' | translate }}</label>
                        <textarea name="cookie" class="form-control" [(ngModel)]="linkedInConfig.cookie" style="height:450px;"></textarea>
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-12" *appHasPermission="'LINKEDINCONFIG.EDIT'">
                    <div class="row">
                        <div class="col-12">
                            <button class="float-right btn btn-success btn--icon-text" [disabled]="f.submitted && f.invalid"><i class="zmdi zmdi-check"></i> {{ 'global.buttons.save' | translate }}</button>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>